<template>
    <div class="panel feature">
        <a v-if="url" :href="url" target="_blank" class="feature-reference-url" />
        <div class="feature-icon-wrapper-outer">
            <div class="feature-icon-wrapper-inner" :class="'circle-rating-' + rating">
                <div v-if="rating !== null" class="feature-rating">
                    {{ rating }}
                    <span>&#47;10</span>
                </div>
                <div v-else class="feature-rating">
                    <span class="v-align-middle">no rating</span>
                </div>
            </div>
        </div>
        <div class="feature-details-wrapper">
            <h1 class="feature-header">{{ name }}</h1>
            <img
                v-if="$mq === 'md'"
                class="great-schools-mobile"
                src="/images/great-schools-logo.png"
                alt="Great Schools logo"
            />
            <p v-if="address">{{ address.street }}, {{ address.city }}, {{ address.state }}, {{ address.zipcode }}</p>
            <p class="feature-misc-details">
                {{ level }} School
                <span>, {{ grades }} |</span>
                {{ num_students }}
                <span>students</span>
            </p>
            <div class="feature-last-row">
                <p class="feature-distance">Distance: {{ distance }} miles</p>
                <img class="great-schools-logo" src="/images/great-schools-logo.png" alt="Great Schools logo" />
            </div>
        </div>
    </div>
</template>

<script>
import { layerDetails } from 'mixins';
export default {
    mixins: [layerDetails],
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    computed: {
        url() {
            return this.rowProperty('properties.url');
        },
        rating() {
            return this.rowProperty('properties.rating');
        },
        name() {
            return this.rowProperty('properties.name');
        },
        distance() {
            return this.rowProperty('properties.distance');
        },
        level() {
            return this.rowProperty('properties.level');
        },
        grades() {
            return this.rowProperty('properties.grades');
        },
        num_students() {
            return this.rowProperty('properties.num_students');
        },
        address() {
            return this.rowProperty('properties.address', null);
        },
    },
};
</script>
